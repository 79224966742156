import React, { useState } from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import * as styles from "../styles/components/counter-block.module.css";

const CounterBlock = (props) => {

  const {
    content,
    number,
    goalNumber,
    goalText,
    bg
  } = props;

  const [visible, setVisible] = useState(false);

  function onChangeVisibility (isVisible) {
    setVisible(isVisible);
  }

  const easeOutQuad = (x) => {
    return 1 - (1 - x) * (1 - x);
  };

  function round() {
    return parseFloat(number/goalNumber).toFixed(2);
  }

  return (
    <div className={cn(styles.root, goalNumber && styles.hasGoal)}>
      <Container>
        <div className="grid">
          <div className="col-12 col-md-6">
            {content.heading && (<h2 className={cn("h1", styles.heading)}>{content.heading}</h2>)}
          </div>
          <div className="col-12 col-md-6">
            <div className="content-xl">
              {content._rawContent && (<PortableText blocks={content._rawContent} />)}
            </div>
          </div>
        </div>

        {number && (
          <VisibilitySensor
            onChange={onChangeVisibility} 
            active={!visible}
            partialVisibility={true}
          >
            {({ isVisible }) => (
              <>
                <div className={cn(styles.number, isVisible && styles.active)}>
                  <Container>
                    {isVisible ? <CountUp start={1000000} end={number} duration={3} separator="," prefix="<span class='prefix'>$</span>" easingFn={easeOutQuad} /> : null}
                  </Container>
                </div>
                {goalNumber && (
                  <div className={cn(styles.progress, isVisible && styles.active)}>
                    <Container>
                      <div className={styles.bar}><span style={{width: isVisible ? parseFloat(number/goalNumber * 100).toFixed(2) + "%" : undefined}}></span></div>
                      {goalText && <div className={styles.label}>Funds Committed: ${goalText}</div>}
                    </Container>
                  </div>
                )}
              </>
            )}
          </VisibilitySensor>
        )}

        {bg && (<div className={styles.bg}></div>)}
      </Container>
    </div>
  );
}

export default CounterBlock;
